// Add your custom JS here.
document.addEventListener("DOMContentLoaded", function () {

	//-------------------------------//
	//       MENU LANGUES
	//-------------------------------//

	// const langueActive = document.getElementById("langue-active");
	// const menuLangues = document.getElementById("menu-langues");
	// langueActive.onclick = function (e) {
	// 	e.preventDefault();
	// 	if (menuLangues.style.display === "none"){
	// 		menuLangues.style.display = "block";
	// 	} else {
	// 		menuLangues.style.display = "none";
	// 	}
	// }


	function fixedMenu() {
		let navbar = document.getElementById("main-nav");
		let sticky = navbar.offsetHeight / 1.5;
		if (window.pageYOffset >= sticky) {
			document.querySelector(".middlenav").classList.add("d-none");
			document.querySelector(".topnav-container").classList.add("d-none");
			navbar.classList.add("bg-dark");
			navbar.querySelector(".navbar-brand").classList.add("logo-sm");
		} else {
			document.querySelector(".middlenav").classList.remove("d-none");
			document.querySelector(".topnav-container").classList.remove("d-none");
			navbar.classList.remove("bg-dark");
			navbar.querySelector(".navbar-brand").classList.remove("logo-sm");
		}
	}


	window.onscroll = function () {
		fixedMenu();
	};


	const equipiers = document.querySelectorAll('.article--equipier');
	equipiers.forEach(function(m){
		let equipiersHeight = m.offsetWidth;
		m.querySelector('.article__image').style.height = equipiersHeight - 30 + "px";
	});

	// const menu_items = document.querySelectorAll('a.dropdown-toggle.nav-link');
	// menu_items.forEach(function(item){
	// 	if( item.dataset.href ) {
	// 		item.onclick = function () {
	// 			document.location = item.dataset.href;
	// 		};
	// 	}
	// });

});
